import { useEffect, useState } from 'react';

export function useLocalStorage<T>(
  key: string,
  initialValue: T | null,
): [T | null, (value: T | null) => void] {
  const [value, setValue] = useState<T | null>(initialValue);

  const storeValue = (newValue: T | null) => {
    try {
      const json = localStorage.getItem(key);
      if (newValue === null && json) {
        localStorage.removeItem(key);
      } else {
        localStorage.setItem(key, JSON.stringify(newValue));
      }
      setValue(newValue);
    } catch (e) {
      console.error('Failed to access localStorage:', e);
      setValue(newValue); // Still update state even if localStorage fails
    }
  };

  useEffect(() => {
    const setValueFromJson = (json: string | null) => {
      let newValue = null;
      try {
        newValue = json ? (JSON.parse(json) as T) : null;
      } catch (e) {
        console.error('Failed to parse localStorage value:', e);
      }
      setValue(newValue);
    };

    try {
      const json = localStorage.getItem(key);
      if (!json && initialValue) {
        localStorage.setItem(key, JSON.stringify(initialValue));
      }
      if (json) {
        setValueFromJson(json);
      }
    } catch (e) {
      console.error('Failed to access localStorage:', e);
      if (initialValue) {
        setValue(initialValue);
      }
    }

    const callback = (event: StorageEvent) => {
      if (event.key !== key) return;
      setValueFromJson(event.newValue);
    };

    window.addEventListener('storage', callback);
    return () => {
      window.removeEventListener('storage', callback);
    };
  }, [key, initialValue]);

  return [value, storeValue];
}
